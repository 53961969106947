import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import SecondScreen from "../screens/SecondScreen";
import MainTabs from "./MainTabs";
import Cart from "../screens/Cart";
import CheckOut from "../screens/CheckOut";
import CartItem from "../screens/CartItem";




const MainStack = createNativeStackNavigator();
const Main = (menus:any) => {
  return (
    <MainStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <MainStack.Screen name="MainTabs" component={MainTabs} />
      <MainStack.Screen name="SecondScreen" component={SecondScreen} />
      <MainStack.Screen name="Cart" component={Cart} />
      <MainStack.Screen name="CheckOut" component={CheckOut} />
      <MainStack.Screen name="CartItem" component={CartItem} initialParams={{ ItemID: menus.id }}/>


    </MainStack.Navigator>
  );
};

export default Main;
