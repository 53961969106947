//import 'react-native-url-polyfill/auto';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = 'https://dlorghqmtqifzrijgpvg.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRsb3JnaHFtdHFpZnpyaWpncHZnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjgzMjU2NTEsImV4cCI6MTk4MzkwMTY1MX0.Ydlsi2j8J7e1GIx2qUi4hhfBqPhusfhY9iUujMWg6hg'
// Better put your these secret keys in .env file
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  localStorage: AsyncStorage as any,
  detectSessionInUrl: false// Prevents Supabase from evaluating window.location.href, breaking mobile
});
