import React, { useEffect, useState } from "react";
import { View, StyleSheet, Dimensions, ScrollView, ImageBackground, Image, TouchableOpacity,} from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../initSupabase";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
  TextInput,
  Picker,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";
import { Route, RouteProp, useRoute } from "@react-navigation/native";


export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "CartItem">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<null | boolean>(null);
  const [menu, setMenu] = useState(null)

  const route = useRoute<RouteProp<MainStackParamList, "CartItem">>();
  const { ItemID } = route.params;

  useEffect(() => {
		const session = supabase.auth.session();
		setSession(session);
		setUser(session ? true : false);
		const { data: authListener } = supabase.auth.onAuthStateChange(
			async (event, session) => {
				console.log(`Supabase auth event: ${event}`);
				setSession(session);
				setUser(session ? true : false);
			}
		);
		return () => {
			authListener!.unsubscribe();
		};
	}, [user]);
  
  const [menuname, setMenuname] = useState(null)
  const [price, setPrice] = useState()
  const [Description, setDescription] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [image, setImage] = useState()
  const [rating, setRating] = useState(0)
  //const [priceTotal, setSetpricetotal] = useState()

  useEffect(()=>{
    const fetchItem = async () => { 
    const {data, error} = await supabase
      .from('Menu')
      .select()
      .eq('id',ItemID)
      .single()
      if (error?.message === 'Record not found'){ 
        setFetchError(null)
        setMenuname(null)
        setPrice(null)
      } 
      if (data){
        setMenuname(data.menu_name)
        setPrice(data.price)
        setDescription(data.discription)
        setImage(data.image)
        setRating(data.rating)
        setFetchError(null)
        console.log(data)
      }
    }
    fetchItem()
    }, [ItemID])


    const Add_Cart = async () => {
      let total = price * quantity;
      setLoading(true)
      const { data,error } = await supabase
      .from('tblCart')
      .insert([{  table:session?.user?.email,cartID:ItemID, order:menuname,price:price ,quantity:quantity, Item_total:total}])
    if (error) alert(error.message)
      setLoading(false)
    if(data){
      console.log(data)
      alert("Succesfull")
    }
    }
    console.log(route.params.ItemID)

    const ADDQuantity = () => {
        let newNumber = setQuantity(quantity > 100 ? 0 : quantity + 1);
    };
    const MinusQuantity = () => {
      let newNumber = setQuantity(quantity  < 1 ? 0 : quantity - 1);
  }

    // const ItemTotal = () => {
    //     let total = price * quantity;

    // }
    const Add_Rating = async () => {
      let total = rating + Ratings;
      setLoading(true)
      const { data,error } = await supabase
      .from('Menu')
      .update([{ rating:total}])
      .eq('id',ItemID)
    if (error) alert(error.message)
      setLoading(false)
    if(data){
      console.log(data)
      alert("Succesfull")
     
    }
    }

    const [Ratings, setRatings] = useState(null);
    const items = [
        { label: '★★★★★ ', value: 5 },
        { label: '★★★★ ', value: 4 },
        { label: '★★★ ', value: 3 },
        { label: '★★ ', value: 2 },
        { label: '★ ', value: 1 },
    ];
  return (
    <Layout>
      <TopNav
        middleContent={menuname}
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
        }
        leftAction={() => navigation.goBack()}
       
      />
      <ImageBackground
        source={require("../../assets/images/seafoodtwo.jpg")}
        style={styles.backgroundImage}
      >
        <Section style={styles.card}>
              <SectionContent>
                <Section style={styles.container}>
                  <Image style={styles.image} source={image}/>
                        <SectionContent>
                          <View style={{ alignItems: "center" }}>
                            <Text size='xl'>{menuname}</Text>
                            <Text size='lg'>₱ {price}</Text>
                          </View>
                            <Text size='md' style={{ textAlign: "center" }}>Description: {Description}</Text>
                            <Text size='md' style={{ textAlign: "center" }}>Rating: {rating} ★</Text>
            <SectionContent style={styles.containerRating }>
            <Picker
                        items={items}
                        value={Ratings}
                        placeholder="Rate"
                        onValueChange={(val) => setRatings(val)}
                    />
                    <Button text="Submit" color='#FFD700' style={{ marginLeft: 20, width: 40}} onPress={() => Add_Rating()}/>
            </SectionContent>
                        <SectionContent  style={styles.containerQuantity}>
                          <Button text="-" size='sm' color='#FFD700' style={{paddingLeft: 20, marginLeft: 30}} width={50} 
                              textStyle={{color: "black",textTransform: "uppercase",}} onPress={() => MinusQuantity()} />
                            <TextInput 
                                editable={false} selectTextOnFocus={false}
                                value={quantity.toString()}
                                onChangeText={(val) => setQuantity(val)}
                            />
                          <Button text="+" size='sm' color='#FFD700' style={{paddingRight: 20, marginRight: 30}}width={40} 
                              textStyle={{color: "black",textTransform: "uppercase", }}onPress={() => ADDQuantity()} />
                </SectionContent>
            <Button text="Add to Cart" color='#FFD700' style={{ marginLeft: 20, width: 40}}
                textStyle={{
                    color: "black",
                    textTransform: "uppercase",
                }}
                onPress={() => Add_Cart()}
            />
        </SectionContent>
        </Section>
        </SectionContent>
      </Section>
      </ImageBackground>
    </Layout>
  );
}

const styles = StyleSheet.create({
    containerQuantity: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: "90%"
      
    },
    containerRating:{
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      //width: "90%"

    },
    container: {
      flex: 1,
      alignItems: "center", // Center horizontally
      justifyContent: "center", // Center vertically
      alignSelf: "center",
      width: "90%"
      
    },
    card: {
      borderWidth: 10,
      borderColor: "#FFD700",
      backgroundColor: "", // Background color for the card
      borderRadius: 10, 
      shadowColor: "black", 
      width: 315,
      shadowOffset: {
        width: 10, 
        height: 5, 
      },
      shadowOpacity: 0.25, // Shadow opacity
      shadowRadius: 5, // Shadow radius
      elevation: 5, 
      marginBottom: 10,
      padding: 20, 
      alignItems: "center", 
    },
    backgroundImage: {
      flex: 1,
      resizeMode: "cover",
      justifyContent: "center",
      alignItems: "center",
    },
    image: { 
      width: 300,
      height: 200,
      borderRadius: 10,
      marginBottom: 10,
      marginTop: 10,
      alignItems: "center", // Center card content horizontally
    },
  });




