import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { themeColor, useTheme } from "react-native-rapi-ui";
import TabBarIcon from "../components/utils/TabBarIcon";
import TabBarText from "../components/utils/TabBarText";

import Home from "../screens/Home";
import About from "../screens/About";
import Profile from "../screens/Profile";

const Tabs = createBottomTabNavigator();

const MainTabs = () => {
  const { isDarkmode } = useTheme();

  // Define the color for the tab bar icons
  const iconColor = isDarkmode ? "#FFD700" : "#000"; // Set the desired color here

  return (
    <Tabs.Navigator
      screenOptions={{
        headerShown: false,
        tabBarStyle: {
          borderTopColor: isDarkmode ? themeColor.dark100 : "#c0c0c0",
          backgroundColor: isDarkmode ? themeColor.dark200 : "#FFD700",
        },
        tabBarLabelStyle: {
          color: "#000", // Set the text color to black
          fontWeight: "bold",
          fontFamily: "Ubuntu_400Regular", // Set the font family to Impact
          fontSize: 13
        },
      }}
    >
      <Tabs.Screen
        name="H O M E"
        component={Home}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              icon={"md-home"}
              style={{ color: focused ? iconColor : "#999" }} // Set the color here
            />
          ),
        }}
      />
      {/* <Tabs.Screen
        name="P R O F I L E"
        component={Profile}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              icon={"person"}
              style={{ color: focused ? iconColor : "#999" }} // Set the color here
            />
          ),
        }}
      /> */}
      <Tabs.Screen
        name="A B O U T"
        component={About}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              icon={"ios-information-circle"}
              style={{ color: focused ? iconColor : "#999" }} // Set the color here
            />
          ),
        }}
      />
    </Tabs.Navigator>
  );
};

export default MainTabs;
