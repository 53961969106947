import React, { useEffect, useState } from "react";
import {   View,StyleSheet, Dimensions, ScrollView } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  TopNav,
  Text,
  themeColor,
  useTheme,
  Section,
  SectionContent,Button,TextInput, Picker, CheckBox, RadioButton, SectionImage,
} from "react-native-rapi-ui";
import { Entypo, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { SafeAreaView } from "react-native-safe-area-context";
import { Session } from '@supabase/supabase-js';
import { supabase } from "../initSupabase";
import { set } from "lodash";


const { width } = Dimensions.get('window');
const gap = 12;
const itemPerRow = 3;
const totalGapSize = (itemPerRow - 1) * gap;
const windowWidth = width;
const childWidth = (windowWidth - totalGapSize) / itemPerRow;


export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "CheckOut">) {
  const { isDarkmode, setTheme } = useTheme();
  const [fetchError, setFetchError] = useState(null)
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<null | boolean>(null);
  const [loading, setLoading] = useState(true)
  const [cart, setCart] = useState([])
  const [Address, setAddress] = useState([])
  const [USERNAME, setUSERNAME] = useState(null)
  const [PNUMBER, setPNUNMER] = useState(null)
  const [Status, setStatus] = useState("Pending")  
  const [orderID, setOrderID] = useState(null)

  useEffect(() => {
		const session = supabase.auth.session();
		setSession(session);
		setUser(session ? true : false);
		const { data: authListener } = supabase.auth.onAuthStateChange(
			async (event, session) => {
				console.log(`Supabase auth event: ${event}`);
				setSession(session);
				setUser(session ? true : false);
			}
		);
		return () => {
			authListener!.unsubscribe();
		};
	}, [user]);
  

  useEffect(() => {
    const fetchProfile = async () => {
      const { data, error } = await supabase
        .from("tblProfiles")
        .select()
        .eq("email", session?.user?.email)
        .single();
      if (error?.message === "Record not found") {
        setFetchError(null);
        setUSERNAME(null);
      }
      if (data) {
        setUSERNAME(data.name);
        setFetchError(null);
      }
    };
    fetchProfile();
  }, [session?.user?.email]);

  useEffect(()=>{
    const fetchMenu = async () => { 
    const {data, error} = await supabase
      .from('tblCart')
      .select('order,price,quantity,Item_total')
      .eq('table',session?.user?.email)
      if (error?.message){ 
        setCart([])
      } 
      if (data){
        setCart(data)

      }
    }
    fetchMenu()
    }, )
  
        const cartQuantity = cart.length;
        let total = 0;
        let subtotal = 0;
        const Total = cart.map((carts:any) =>
        total += carts.price * carts.quantity)
        const subTotal = cart.map((carts:any) => 
        subtotal += carts.Item_total * cartQuantity)

        //generates random id;
          const guid = () => {
            let s4 = () => {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
            return s4() + s4();
          }

          console.log(guid());
          //"c2181edf-041b-0a61-3651-79d671fa3db7"    

      const PlaceOder = async () => {
        setLoading(true)
        const { data,error } = await supabase
        .from('tblOrders')
        .insert([{ orderID:guid(),  order:cart,table:USERNAME, tot_amount:total,status:"Pending",branch:branch }])
      if (error) alert(error.message)
        setLoading(false)
      if(data){
        console.log(data)
        alert("Succesfull")
      }
      }
 
      const Remove_Cart = async () => {
        setLoading(true)
        const { data,error } = await supabase
        .from('tblCart')
        .delete()
        .eq('table',session?.user?.email)
      if (error) alert(error.message)
        setLoading(false)
      if(data){
        console.log(data)
      }
      } 

      const quit = async () => {
        setTimeout(async () => {
        const { error } = await supabase.auth.signOut();
        if (!error) {
          alert("Signed out!");
        }
        if (error) {
          alert(error.message);
        }
      },10000)};

      const payMent = async () => {
        setLoading(true)
      const {data, error} = await supabase
        .from('payment')
        .select()
        .eq('mode',paymentOptions)
        if (error?.message){ 
         
        } 
        if (data){
          setMode(data)
          console.log(data)
        }
      }
  

    const [branch, setbranch] = useState(null);
    const items2 = [
        { label: 'Calapan Branch', value: 'Calapan' },
        // { label: 'Naujan Branch', value: 'Naujan' },
    ];

  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent="CHECK OUT"
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
        }
        leftAction={() => navigation.goBack()}
      
      />
      <View style={styles.container}>
      <View style={styles.card}>
      <View style={{flex: 1,}}>
        {/* This text using ubuntu font */}
        <Section>
        {cart && ( 
        <SectionContent >
            {cart.map((carts:any) => (
              <Section  key={carts.id}> 
                  <SectionContent style={styles.containerItem}>
                    <Text size="xl" style={{ textAlign: "center" }}>{carts.order}</Text>
                    <Text fontWeight="bold" size="md" style={{ textAlign: "center" }}>Total: ₱ = {carts.Item_total}</Text>
                    </SectionContent>
              </Section>
            ))}
       </SectionContent> )}
       </Section>
            <Section>
                <SectionContent  style={styles.containerShipping}>
                      <Text fontWeight="bold" style={{marginBottom: 10,alignSelf:'center' }}>
                        <MaterialIcons name="payments" size={20} color={isDarkmode ? themeColor.white100 : themeColor.dark} /> Payment Details:</Text>
                            <Text fontWeight="bold" style={{ textAlign: "center" }}>
                              Total  Payment: ₱ {parseFloat(total)}
                            </Text>
                  </SectionContent>
                <Section>
                  <SectionContent   >
                      <Text fontWeight="bold" style={{marginBottom: 10,textAlign:"center", color:"black"}}>Your Table:  {USERNAME}</Text>
                            <Text fontWeight="bold" style={{textAlign:"center", marginBottom: 10}}>Branch Option</Text>
                            <Picker
                              items={items2}
                              value={branch}
                              placeholder="Choose Branch"
                              onValueChange={(val) => setbranch(val)}
                            />
                        </SectionContent>
                    </Section>
                 
              </Section>
      </View>
      </View>
      </View>
      </ScrollView>
      <Button text="Place Order" textStyle={{color:"#000"}} color='#FFD700'  onPress={()=> {PlaceOder(),Remove_Cart(),quit()}} />
    </Layout>
  );
}
const styles = StyleSheet.create({
  containerShipping: {
    flexDirection: 'column',
    alignSelf: "center",
    justifyContent: 'space-between',
    backgroundColor: '#FFD700',
    marginBottom: 10,
    borderRadius: 10
  },
  containerItem: {
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: 'space-between',
    backgroundColor: '#FFD700',
    marginBottom: 10,
    borderRadius: 10
  },
  containerAdrss: {
    marginLeft: 10,
    backgroundColor: '#EEEEEE',
    marginBottom: 10,
  },
  itemsWrap: {
    marginBottom: 5,
    backgroundColor: '#EEEEEE',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: -(gap / 2),
    marginHorizontal: -(gap / 2),
  },
  image: {  
    width: childWidth,
  },
  container: {
    flex: 1,
    alignItems: "center", // Center horizontally
    justifyContent: "center", // Center vertically
    color: "#FFD700"
    
  },
  card: {
    borderWidth: 10,
    borderColor: "#FFD700",
    backgroundColor: "white", // Background color for the card
    borderRadius: 10, // Border radius for the card
    shadowColor: "black", // Shadow color
    shadowOffset: {
      width: 10, // Increase the width to make the card larger
      height: 5, // Increase the height to make the card larger
    },
    shadowOpacity: 0.25, // Shadow opacity
    shadowRadius: 5, // Shadow radius
    elevation: 5, // Android shadow elevation
    marginBottom: 50,
    marginTop: 50,
    width: "90%",
    padding: 20, // Increase the padding to make the card larger
    alignItems: "center", // Center card content horizontally
  },


});

function setMode(data: any[]) {
  throw new Error("Function not implemented.");
}
