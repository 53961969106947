import React from "react";
import { View, StyleSheet, ImageBackground, Image, Text, Linking, ScrollView } from "react-native";

const AboutPage: React.FC = () => {
  return (
    <ScrollView>
      <ImageBackground
        source={require("../../assets/images/seafoodtwo.jpg")}
        style={styles.backgroundImage}
      >
        <View style={styles.container}>
          <View style={styles.content}>
            <Text style={styles.heading}>About Tambuli Kubo Restaurant</Text>
            <Text style={styles.description}>
              Experience the exquisite world of seafood delights through Tambuli. Journey into the exceptional culinary offerings of Tambuli Seafood Kubo with our seamless online ordering system. Discover a diverse menu that weaves together the coastal dining experience in a tapestry of flavors. Our user-friendly interface simplifies your selection process, making it a breeze to explore our tempting seafood dishes and place orders with a few clicks. Enjoy the freedom to personalize your dining adventure by planning your visit in advance. At Tambuli, our commitment is to bring the essence of Tambuli Seafood Kubo to your doorstep, ensuring that every interaction is a savory step towards crafting cherished memories.
            </Text>
            <Image
              source={require("../../assets/images/tb3.jpg")}
              style={styles.restaurantImage}
            />
            <Text style={styles.heading}>Visit Us</Text>
            <Text style={styles.description}>
              Come and experience the extraordinary dish that we Tambuli Seafoods Restaurant have to offer. Visit our restaurant today for a memorable dining experience. Our Tambuli Seafoods Restaurant 2 is located in 85XC+M8X, Bayanan II, Calapan City, 5200 Oriental Mindoro. While our Tambuli Kubo Restaurant Main Branch is located in Inarawan, Labak II, Naujan, 5204 Oriental Mindoro. "Where Every Bite Tells a Delicious Story, Come and Visit us, Tambuli Seafoods Restaurant"
            </Text>
          </View>
          <View style={styles.links}>
            <Text
              style={styles.link}
              onPress={() => Linking.openURL("https://web.facebook.com/jennie.dancel")}
            >
              "Visit us on Tambuli Seafoods Kubo"
            </Text>
            <Text
              style={styles.link}
              onPress={() => Linking.openURL("https://www.google.com/search?q=Tambuli%20Seafoods%20Kubo%202&oq=tam&aqs=chrome.0.69i59j69i57j69i61l2j0i433i512l2j0i512j46i433i512j0i433i512l2.1574j0j4&client=ms-android-samsung-gj-rev1&sourceid=chrome-mobile&ie=UTF-8")}
            >
              "Search us on Google"
            </Text>
            <Text style={styles.description}>
            Tap the link above  
            </Text>
          </View>
          <Text style={styles.heading}>Our Top Specialties</Text>
          <Text style={styles.description}>
            "Tambuli Seafoods Top Food of the Year"
          </Text>
          <View style={styles.specialtyCard}>
            <Image
              source={require("../../assets/images/tb8.jpg")}
              style={styles.specialtyImage}
            />
            <Image
              source={require("../../assets/images/tb9.jpg")}
              style={styles.specialtyImage}
            />
            <Image
              source={require("../../assets/images/th6.jpg")}
              style={styles.specialtyImage}
            />
            <Text style={styles.specialtyDescription}>
              Enjoy a wide variety of fresh seafood dishes prepared with exquisite flavors. Our exceptional service and relaxing atmosphere will make your dining experience memorable.
            </Text>
          </View>
          <Text style={styles.heading}>Contact Us</Text>
          <Text style={styles.contactInfo}>
            Owner: Ms. Jennie Dencel {"\n"}
            Call: 0975 712 0698 {"\n"}
            Address: Bayanan II, Calapan City, Oriental Mindoro {"\n"}
            Near at: Malapote Gas Station {"\n"}
            Social Media: Tambuli Seafoods on Facebook {"\n"}
          </Text>
        </View>
      </ImageBackground>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 30,
    marginBottom: 30,
    borderRadius: 10,
    textAlign: "center",
    width: "90%",
    padding: 20, // Added padding to the entire content
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    marginTop: 20,
    width: "90%",
  },
  heading: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#FFD700",
    marginBottom: 20,
    paddingTop: 15,
  },
  description: {
    fontSize: 16,
    color: "white",
    marginBottom: 20,
    textAlign: "center",
  },
  specialties: {
    fontSize: 16,
    color: "#FFD700",
    marginBottom: 20,
    textAlign: "left",
  },
  contactInfo: {
    fontSize: 16,
    color: "#FFD700",
    marginBottom: 20,
    textAlign: "left",
  },
  restaurantImage: {
    width: "100%",
    height: 450,
    borderRadius: 10,
    marginBottom: 20,
    alignSelf: "center",
  },
  links: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    color: "#FFD700",
    fontSize: 21,
    marginLeft: 10,
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    alignItems: "center",
  },
  specialtyCard: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: 20,
    borderRadius: 10,
    marginVertical: 10,
  },
  specialtyImage: {
    width: "100%",
    height: 350,
    borderRadius: 10,
    marginBottom: 10,
  },
  specialtyDescription: {
    fontSize: 16,
    color: "#000",
  },
});

export default AboutPage;
