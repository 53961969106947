import React, { useEffect, useState } from "react";
import { StatusBar } from "expo-status-bar";
import { Linking, StyleSheet, Text as RNText, Dimensions } from "react-native";
import {
  ScrollView,
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  Image,
  ImageBackground,
} from "react-native";
import { supabase } from "../../initSupabase";
import { AuthStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import {
  Layout,
  Text,
  TextInput,
  Button,
  useTheme,
  themeColor,
  Picker,
  SectionImage,
} from "react-native-rapi-ui";

export default function ({
  navigation,
}: NativeStackScreenProps<AuthStackParamList, "Login">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<string>("admin");

  async function login() {
    setLoading(true);
    const { user, error } = await supabase.auth.signIn({
      email: tableOptions,
      password: "table",
    });
    if (!error && !user) {
      setLoading(false);
      alert("Check your email for the login link!");
    }
    if (error) {
      setLoading(false);
      alert(error.message);
    }
  }

  const [tableOptions, settableOPtions] = useState("");
  const items = [
      { label: 'Table 1', value: 'table1@tambuli' },
      { label: 'Table 2', value: 'table2@tambuli' },
      { label: 'Table 3', value: 'table3@tambuli' },
      { label: 'Table 4', value: 'table4@tambuli' },
      { label: 'Table 5', value: 'table5@tambuli' },
      { label: 'Table 6', value: 'table6@tambuli' },
  ];
  return (
    <KeyboardAvoidingView behavior="height" enabled style={{ flex: 1 }}>
      <Layout >
      <ImageBackground
            resizeMode="cover"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              flex: 1,
              justifyContent: "flex-end",
            }}
            source={require("../../../assets/images/seafoodtwo.jpg")}
          />
        <ScrollView
        >
          <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 500,
                  paddingTop: 30,
                }}
              >
          </View>
          <View
                style={{
                  flex: 3,
                  paddingHorizontal: 20,
                  paddingBottom: 360,
                  paddingTop: 10,
                }}
              >
              <Image
                  resizeMode="contain"
                  style={{
                    alignSelf: "center",
                    paddingTop: 10,
                    height: 145, // Adjust the height as needed
                    width: 200, // Adjust the width as needed
                  }}
                  source={require("../../../assets/icon.png")}
                />
            <Text
                  fontWeight="bold"
                  style={{
                    alignSelf: "center",
                    padding: 30,
                    color: "white",
                  }}
                  size="h3"
                >
              
              Choose Your Table
            </Text>
            <Picker
                          items={items}
                          value={tableOptions}
                          placeholder="Choose your table"
                          onValueChange={(val) => settableOPtions(val)}/>
            <Button
                  text={loading ? "Loading" : "Continue"}
                  color="#FFD700"
                  style={{marginTop:20}}
                  onPress={() => {
                    login();
                  }}
                  disabled={loading}
                  textStyle={{
                    color: "black",
                    textTransform: "uppercase",
                  }}
                />
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 50,
                    justifyContent: "center",
                  }}
                >
                <Text
                      size="md"
                      fontWeight="bold"
                      style={{
                        color: "white",
                        fontSize: 15,
                        marginLeft: 5,
                      }}
                    >
                </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 10,
                justifyContent: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  isDarkmode ? setTheme("light") : setTheme("dark");
                }}
              >
                <Text
                  size="md"
                  fontWeight="bold"
                  style={{
                    marginLeft: 5,
                  }}
                >

                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </Layout>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 10,
    
  },
  card: {
    borderWidth: 10,
    borderColor: "#FFD700",
    borderRadius: 10,
    shadowColor: "black",
    maxWidth: "100%",
    shadowOffset: {
      width: 10,
      height: 5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    elevation: 5,
    marginBottom: 10,
    padding: 20,
    alignItems: "center",
  },
});
